<template>
	<div class="statistics">
		<ast-statistics-not-found v-if="!isLoading && !isValidId" />
		<section v-else>
			<img :src="logoUrl" class="repository-logo" onerror="this.style.display = 'none'" />
			<h1 class="hidden-print">
				Repository Statistics
				<svg-icon v-if="isLoading" icon="spinner" pulse fixed />
			</h1>
			<h2 class="visible-print">
				{{ selectedSaleName }}
			</h2>
			<div v-if="!isLoading">
				for {{ userDescription }}
				<div v-if="sales.length" class="hidden-print">
					<label>Sale:</label>
					<select class="select is-inline" @change="event => setSaleId(event.target.value)">
						<optgroup v-if="activeSales.length" label="Active Sales">
							<option
								v-for="sale in activeSales"
								:key="sale.value"
								:value="sale.value"
								:selected="$route.query.saleId === sale.value"
							>
								{{ sale.label }}
							</option>
						</optgroup>
						<optgroup v-if="inactiveSales.length" label="Inactive Sales">
							<option
								v-for="sale in inactiveSales"
								:key="sale.value"
								:value="sale.value"
								:selected="$route.query.saleId === sale.value"
							>
								{{ sale.label }}
							</option>
						</optgroup>
					</select>
					<ul class="nav">
						<router-link
							v-for="link in links"
							:key="link.name"
							:to="{ name: link.name, query: $route.query }"
							tag="li"
						>
							{{ link.text }}
						</router-link>
					</ul>
				</div>
				<p v-else class="no-sales">No sales found for {{ clinicCode }}.</p>
			</div>
		</section>
		<transition name="fade" mode="out-in">
			<router-view v-if="!isLoading && isValidId && sales.length" class="content" />
		</transition>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import { statisticsUserRoutes } from '@router/routes'
import AstStatisticsNotFound from '@components/view/StatisticsNotFound'
export default {
	components: {
		AstStatisticsNotFound,
	},
	props: {
		clinicCode: {
			type: String,
			required: true,
		},
		userType: {
			type: String,
			required: true,
		},
		id: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			links: [],
			isLoading: true,
			isValidId: false,
			userInfo: {},
			sales: [],
		}
	},
	computed: {
		activeSales() {
			return this.sales.filter(s => s.active)
		},
		inactiveSales() {
			return this.sales.filter(s => !s.active)
		},
		logoUrl() {
			return `${window.config.keystoneRootUrl}/Keystone/Logo.aspx?ClinicCode=${this.clinicCode}`
		},
		selectedSaleName() {
			const selectedSale = this.sales.find(x => x.value === this.$route.query.saleId)
			if (selectedSale) return selectedSale.label
			return ''
		},
		userDescription() {
			if (this.userType === 'client') return `client ${this.userInfo.name}`
			if (this.userType === 'user') return `user ${this.userInfo.name}`
			const consignerTerm = this.translate('consigner').toLowerCase()
			if (this.userType === 'repository') return `all ${consignerTerm}s`
			if (this.userType === 'consigner') return `${consignerTerm} ${this.userInfo.name}`
			return ''
		},
	},
	created() {
		const navigationLinks = [
			{
				name: 'statistics-summaries',
				text: 'Summary',
			},
			{
				name: 'statistics-viewing-log',
				text: 'Full Viewing Log',
			},
			{
				name: 'statistics-submissions',
				text: 'Submission Report',
			},
			{
				name: 'statistics-consigner-clients',
				text: 'Clients',
			},
		]
		this.links = navigationLinks.filter(link =>
			statisticsUserRoutes[this.userType].includes(link.name)
		)
	},
	async mounted() {
		this.isLoading = true
		try {
			await this.getUserInfo()
			await this.getRepositoryData(this.clinicCode)
			await this.getSales()
		} finally {
			this.isLoading = false
		}
	},
	methods: {
		...mapActions(['getRepositoryData']),
		async getUserInfo() {
			try {
				let params = { clinicCode: this.clinicCode }
				if (this.userType === 'repository') {
					params.securityToken = this.id
				} else {
					params.id = this.id
				}
				const { data } = await this.$api.statistics.getUserInfo(params)
				this.userInfo = data
				this.isValidId = true
			} catch (err) {
				this.isValidId = false
				throw err
			}
		},
		async getSales() {
			try {
				const { data } = await this.$api.repository.getSales(this.clinicCode)
				this.sales = data
				if (!this.sales.length) return
				const oldestSaleId = this.sales[0].value
				if (
					!this.$route.query.saleId ||
					!this.sales.map(x => x.value).includes(this.$route.query.saleId)
				) {
					this.setSaleId(oldestSaleId)
				}
			} finally {
			}
		},
		setSaleId(saleId) {
			this.$router.push({ query: { saleId } })
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
.statistics {
	display: flex;
	flex-direction: column;
	background: var(--secondary-bg);
	min-height: 100%;
	padding: 15px;
	.content {
		position: relative;
		flex-grow: 1;
	}
}
h1 {
	font-size: 1.2em;
}
h2 {
	margin-bottom: 0.5em;
	font-size: 1em;
}
.visible-print {
	@media screen {
		display: none;
	}
}
.hidden-print {
	@media print {
		display: none;
	}
}
.repository-logo {
	float: right;
	max-width: 50%;
	object-fit: contain;
	object-position: 0 0;
	height: 100px;
	max-height: 100px;
}

@media (max-width: $mqMedium) {
	.repository-logo {
		display: none;
	}
}
select {
	padding: 0.25em;
	margin: 0.5em;
}
.nav {
	display: flex;
	padding: 0;
	margin: 1em 0;
	font-size: 0.9em;
	li {
		display: inline-block;
		padding-bottom: 2px;
		margin: 0 0.75em 0 0;
		text-align: center;
		cursor: pointer;
		user-select: none;
		border-bottom: 1px solid #ccc;
		&:hover {
			border-bottom-color: #aaa;
		}
		&.active {
			border-bottom-color: #0ca;
		}
	}
}
p.no-sales {
	margin: 1em 0;
	opacity: 0.75;
}
</style>
